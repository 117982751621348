/*
 * Vuex store module to manage user preferences
 */

export default {
    
    state: {
        viewAppsInTest: false,
        labelAndInputOnSameRow: true,
        formQuestionsShown: 'block',  // in { 'all', 'block', 'conversational' }
        formReevaluateMode: 'always' // in { 'always', 'noQuestionLeft' }
    },
    // can be seen as computed properties that can be shared across components
    getters: {
        viewAppsInTest: state => {
            return state.viewAppsInTest;
        },
        formQuestionsShown: state => {
            return state.formQuestionsShown;
        },
        formReevaluateMode: state => {
            return state.formReevaluateMode;
        },        
        labelAndInputOnSameRow: state => {
            return state.labelAndInputOnSameRow;
        }
    },
    // logic to change state. must be synchronous. not to be called directly (best practices is to go thru an action)
    mutations: {
        switchViewAppsInTest: (state) => {
            state.viewAppsInTest = !state.viewAppsInTest;
        },
        switchLabelAndInputOnSameRow: (state) => {
            state.labelAndInputOnSameRow = !state.labelAndInputOnSameRow;
        },        
        setFormQuestionsShown: (state, newMode) => {
            state.formQuestionsShown = newMode;
        },
        setFormReevaluateMode: (state, newMode) => {
            state.formReevaluateMode = newMode;
        }
    },    
    // actions are allowed to run asynchronous code, e.g. make an HTTP call and wait for a response
    actions: {
        switchViewAppsInTest: context => {
            context.commit('switchViewAppsInTest');
        },
        switchLabelAndInputOnSameRow: context => {
            context.commit('switchLabelAndInputOnSameRow');
        },
        setFormQuestionsShown: (context, newMode) => { // commit change
            context.commit('setFormQuestionsShown', newMode);
        },
        setFormReevaluateMode: (context, newMode) => { // commit change
            context.commit('setFormReevaluateMode', newMode);
        }        
    }
}