/*
 * Vuex store module to manage localization of UI elements
 */

// Inspired from https://medium.com/developer-rants/simple-dynamic-localization-in-vue-with-vuex-b429c525cd90
export default {
    
    state: {
        languages: ['en', 'es', 'fr'],
        uiLanguage: 'en',

        i18n: {
            "en": {
                "top-menu": {
                    "file": {
                        "workspace": "My workspace",
                        "applications": "My applications",
                        "marketplace": "Marketplace"
                    },
                    "view": {
                        "item": "View",
                        "interaction": {
                            "header": "Interaction mode",
                            "conversational": "Conversational",
                            "block": "Questions by block",
                            "all": "View all questions"
                        },
                        "questions": {
                            "header": "Questions",
                            "on-one-line": "On one line",
                            "evaluation": "Evaluation",
                            "automatic-reevaluation": "Automatic"
                        },
                        "language": {
                            "keep-in-sync": "Keep languages in sync",
                            "in": "Input language",
                            "out": "Output language"
                        },
                        "preferences": {
                            "header": "Preferences",
                            "model-as-cards": "View models as cards",
                            "view-test-releases": "View test releases"
                        }
                    },
                    "manage": {
                        "item":  "Manage",
                        "users": "Users",
                        "healthcheck": "Check status"
                    },
                    "search": "Search...",
                    "user": {
                        "item":  "User",
                        "settings": "User Settings",
                        "get-token": "Get an access token",
                        "logout": "Log out"
                    }
                },
                "workspace": {
                    "title": "My workspace",
                    "favorite-folders": "Favorite folders",
                    "all-folders": "All folders"
                },
                "cases": {
                    "title": "My cases",
                    "click-to-open": "Click to Open Directory",
                },
                "available-applications": {
                    "title": "My applications",
                    "releases": "Available releases",
                    "patches": "Available patches",
                    "open": "Open",
                    "by": "By",
                    "languages": "Languages",
                    "created-on": "Created on",
                    "published-on": "Published on",
                    "format": "Format",
                    "import-file": "Import file",
                    "import-description": "Drag and drop a data file to import your work."
                },
                "work-with-application": {
                    "documents": "Documents",
                    "decisions": "Decisions",
                    "model": "Model",
                    "description": "Description",
                    "type": "Type",
                    "function": "Function",
                    "decisionModel": "Decision model",
                    "open": "Open"
                },
                "evaluate-model": {
                    "document": "Document",
                    "decision": "Decision",
                    "in": "in"
                },
                "questionnaire": {
                    "input-data": "Input Data",
                    "save": "Save",
                    "copy": "Copy",
                    "raw": "Raw",
                    "form": "Form",
                    "output-document": "Generated Document",
                    "decision-result": "Decision Result",
                    "decision-result-evaldata": "Evaluation details",
                    "decision-result-time-total": "Total computation time",
                    "decision-result-time-app-retrieval": "Application retrieval time",
                    "decision-result-time-input-retrieval": "Input retrieval time",
                    "decision-result-time-decision-eval": "Decision evaluation time",
                    "decision-result-language-used": "Language used",
                    "decision-result-nodes": "Decision nodes",
                    "decision-result-internal": "Internal values",
                    "select-input-file": "Select an input data file",
                    "start": "Start",
                    "next": "Reevaluate",
                    "result-not-available-initial": "No result available yet. Please press the Start button.",
                    "result-not-available-nquestions": "Result not available while some questions are not answered. There are at least $COUNT unanswered questions.",
                    "select-a-value": "Select a value",
                    "select-a-type": "Select a type",
                    "of-type": "Of type",
                    "true": "yes",
                    "false": "no",
                    "empty": "empty",
                    "none": "none",
                    "none_m": "none",
                    "none_f": "none",
                    "at-least": "at least",
                    "add": "Add",
                    "set": "Set",
                    "one": "one",
                    "decision-explanation-graph": "Decision explanation graph",
                    "show": "Show",
                    "ltr": "Left to right",
                    "top-bot": "Top to bottom",
                    "restart": "Restart",
                    "overlay": "Please fix the errors in the input data so that the result can be regenerated."
                },
                "user-settings": {
                    "title": "User Settings",
                    "user-id": "User id",
                    "email": "Email",
                    "first-name": "First name",
                    "language": "Language",
                    "account-type": "Account type",
                    "company": "Organization",
                    "administrator": "User administrator",
                    "billing-access": "Billing access",
                    "role": "Role",
                    "level-as-tester": "Level as tester"
                },
                "toasts": {
                    "error": {
                        "auth-error": "Failed to authenticate. Please try again.",
                        "load-apps": "There was a problem loading the applications. Please try again later.",
                        "import-app-version-error": "Invalid version number format. It should follow the convention x.x.x",
                        "import-app-file-type-error": "Not a valid prdoc file, missing model.documentPack",
                        "import-app-not-available-for-org": "Cannot load this data file because your organisation does not have access to the application: ",
                        "healthcheck-error": "A problem occured while loading the Healthcheck. Please try again later.",
                        "work-with-app-error": "A problem occured while loading the application. Please try again later.",
                        "work-with-app-load-decision-error": "A problem occured while loading the decision. Please try again later.",
                        "work-with-app-load-document-error": "A problem occured while loading the document. Please try again later.",
                        "user-invite-error": "A problem occured while inviting the user. Please try again later.",
                        "form-load-error": "There was a problem loading the model. Please try again later.",
                        "form-evaluation-error": "A problem occurred while evaluating the model. Please try again later.",
                        "form-download-json-error": "Error downloading raw data. Please try again.",
                        "form-download-pdf-error": "A problem occured when downloading PDF file. Please try again.",
                        "form-download-msword-error": "A problem occured when downloading MS Word file. Please try again."
                    },
                    "success": {
                        "load-apps-success": "Applications loaded successfully.",
                        "user-invite-success": "User invited successfully.",
                        "form-language-success": "Language used as input and output: "
                    },
                    "warning": {
                        "form-language-mismatch": "Your preferred language is not available in this App. We use the application default language instead: ",
                        "drag-drop-cases-warning": "This document has external references and is a Case document. Rerouting to Cases."
                    }
                },
            },
            "es": {
                "top-menu": {
                    "file": {
                        "workspace": "Mi espacio",
                        "applications": "Mis aplicaciones",
                        "marketplace": "Marketplace"
                    },
                    "view": {
                        "item": "Ver",
                        "interaction": {
                            "header": "Modo de interacción",
                            "conversational": "Conversacional",
                            "block": "Preguntas por bloque",
                            "all": "Ver todas las preguntas"
                        },
                        "questions": {
                            "header": "Preguntas",
                            "on-one-line": "En una linea",
                            "evaluation": "Evaluación",
                            "automatic-reevaluation": "Automática"
                        },
                        "language": {
                            "keep-in-sync": "Sincronizar los lenguajes",
                            "in": "Lenguaje de entrada",
                            "out": "Lenguaje de salida"
                        },
                        "preferences": {
                            "header": "Preferencias",
                            "model-as-cards": "Ver modelos en fichas",
                            "view-test-releases": "Ver versiones de prueba"
                        }
                    },
                    "manage": {
                        "item":  "Ajustes",
                        "users": "Usuarios",
                        "healthcheck": "Estado del sistema"
                    },
                    "search": "Buscar...",
                    "user": {
                        "item":  "Usuario",
                        "settings": "Configuración usuario",
                        "get-token": "Obtenga un token de acceso",
                        "logout": "Salir"
                    }
                },
                "workspace": {
                    "title": "Mi espacio",
                    "favorite-folders": "Carpetas favoritas",
                    "all-folders": "Todas mis carpetas"
                },
                "cases": {
                    "title": "Mi expedientes",
                    "click-to-open": "Haga clic para abrir",
                },
                "available-applications": {
                    "title": "Mis aplicaciones",
                    "releases": "Versiones",
                    "patches": "Parches",
                    "open": "Abrir",
                    "by": "Por",
                    "languages": "Lenguajes",
                    "created-on": "Creada el",
                    "published-on": "Publicada el",
                    "format": "Formato",
                    "import-file": "Importe un fichero",
                    "import-description": "Arrastre y suelte un fichero de datos para importar su trabajo."
                },
                "work-with-application": {
                    "documents": "Documentos",
                    "decisions": "Decisiones",
                    "model": "Modelo",
                    "description": "Descripción",
                    "type": "Tipo",
                    "function": "Función",
                    "decisionModel": "Modelo de decisión",
                    "open": "Abrir"
                },
                "evaluate-model": {
                    "document": "Documento",
                    "decision": "Decisión",
                    "in": "en"
                },
                "questionnaire": {
                    "input-data": "Datos de entrada",
                    "save": "Guardar",
                    "copy": "Copiar",
                    "raw": "JSON",
                    "form": "Cuestionario",
                    "output-document": "Documento generado",
                    "decision-result": "Resultado",
                    "decision-result-evaldata": "Detalles de la evaluación",
                    "decision-result-time-total": "Tiempo total de computación",
                    "decision-result-time-app-retrieval": "Tiempo de carga de la aplicación",
                    "decision-result-time-input-retrieval": "Tiempo de carga de los datos de entrada",
                    "decision-result-time-decision-eval": "Tiempo de evaluación de la decisión",
                    "decision-result-language-used": "Lenguaje usado",
                    "decision-result-nodes": "Nodos de decisión",
                    "decision-result-internal": "Valores internos",
                    "select-input-file": "Seleccione un fichero de datos",
                    "start": "Inicio",
                    "next": "Reevalua",
                    "result-not-available-initial": "El resultado aún no esta disponible. Por favor, pulse el botón de Inicio.",
                    "result-not-available-nquestions": "El resultado no esta disponible mientras queden preguntas sin responder. Hay al menos $COUNT preguntas pendientes.",
                    "select-a-value": "Seleccione una opción",
                    "select-a-type": "Seleccione un tipo",
                    "of-type": "De tipo",
                    "true": "si",
                    "false": "no",
                    "empty": "vacío",
                    "none": "ninguno/a",
                    "none_m": "ninguno",
                    "none_f": "ninguna",
                    "at-least": "al menos",
                    "add": "Añadir",
                    "set": "Poner",
                    "one": "un(a)",
                    "decision-explanation-graph": "Grafo de explicación de la decisión",
                    "show": "Mostrar",
                    "ltr": "De izquierda a derecha",
                    "top-bot": "De arriba hacia abajo",
                    "restart": "Reiniciar",
                    "overlay": "Please fix the errors in the input data so that the result can be regenerated."
                },
                "user-settings": {
                    "title": "Parámetros de usuario",
                    "user-id": "Identificador",
                    "email": "Email",
                    "first-name": "Nombre",
                    "language": "Lenguaje",
                    "account-type": "Tipo de cuenta",
                    "company": "Organización",
                    "administrator": "Administrador de usuarios",
                    "billing-access": "Acceso a datos de facturación",
                    "role": "Rol",
                    "level-as-tester": "Nivel como probador"
                },
                "toasts": {
                    "error": {
                        "auth-error": "Fallo de autenticación. Por favor, inténtelo de nuevo.",
                        "load-apps": "Hubo un problema al cargar las aplicaciones. Por favor, inténtelo de nuevo más tarde.",
                        "import-app-version-error": "Formato de número de versión no válido. Debe seguir la convención x.x.x",
                        "import-app-file-type-error": "No es un archivo prdoc válido, falta model.documentPack",
                        "import-app-not-available-for-org":  "No se ha podido cargar el fichero de datos porque su organización no tiene acceso a la aplicación: ",
                        "healthcheck-error": "Se produjo un problema al cargar la comprobación de estado Healthcheck. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-error": "Se produjo un problema al cargar la aplicación. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-load-decision-error": "Se produjo un problema al cargar la decisión. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-load-document-error": "Se produjo un problema al cargar el documento. Por favor, inténtelo de nuevo más tarde.",
                        "user-invite-error": "Se produjo un problema al invitar al usuario. Por favor, inténtelo de nuevo más tarde.",
                        "form-load-error": "Hubo un problema al cargar el modelo. Por favor, inténtelo de nuevo más tarde.",
                        "form-evaluation-error": "Se produjo un problema al evaluar el modelo. Por favor, inténtelo de nuevo más tarde.",
                        "form-download-json-error": "Error al descargar los datos en formato JSON. Por favor, inténtelo de nuevo.",
                        "form-download-pdf-error": "Se produjo un problema al descargar el archivo PDF. Por favor, inténtelo de nuevo.",
                        "form-download-msword-error": "Se produjo un problema al descargar el archivo de MS Word. Por favor, inténtelo de nuevo.",
                        
                    },
                    "success": {
                        "load-apps-success": "Aplicaciones cargadas correctamente.",
                        "user-invite-success": "Usuario invitado correctamente.",
                        "form-language-success": "Idioma de entrada y de salida: "
                    },
                    "warning": {
                        "form-language-mismatch": "Su idioma favorito no está disponible en esta App. Usaremos el idioma por defecto de la aplicación en su lugar: ",
                        "drag-drop-cases-warning": "Este documento tiene referencias externas y es un documento de caso. Navengando a Expedientes."
                    }
                },
            },
            "ca": {
                "top-menu": {
                    "file": {
                        "workspace": "Mi espacio",
                        "applications": "Mis aplicaciones",
                        "marketplace": "Marketplace"
                    },
                    "view": {
                        "item": "Ver",
                        "interaction": {
                            "header": "Modo de interacción",
                            "conversational": "Conversacional",
                            "block": "Preguntas por bloque",
                            "all": "Ver todas las preguntas"
                        },
                        "questions": {
                            "header": "Preguntas",
                            "on-one-line": "En una linea",
                            "evaluation": "Evaluación",
                            "automatic-reevaluation": "Automática"
                        },
                        "language": {
                            "keep-in-sync": "Sincronizar los lenguajes",
                            "in": "Lenguaje de entrada",
                            "out": "Lenguaje de salida"
                        },
                        "preferences": {
                            "header": "Preferencias",
                            "model-as-cards": "Ver modelos en fichas",
                            "view-test-releases": "Ver versiones de prueba"
                        }
                    },
                    "manage": {
                        "item":  "Ajustes",
                        "users": "Usuarios",
                        "healthcheck": "Estado del sistema"
                    },
                    "search": "Buscar...",
                    "user": {
                        "item":  "Usuario",
                        "settings": "Configuración usuario",
                        "get-token": "Obtenga un token de acceso",
                        "logout": "Salir"
                    }
                },
                "workspace": {
                    "title": "Mi espacio",
                    "favorite-folders": "Carpetas favoritas",
                    "all-folders": "Todas mis carpetas"
                },
                "cases": {
                    "title": "Mi expedientes",
                    "click-to-open": "Haga clic para abrir",
                },
                "available-applications": {
                    "title": "Mis aplicaciones",
                    "releases": "Versiones",
                    "patches": "Parches",
                    "open": "Abrir",
                    "by": "Por",
                    "languages": "Lenguajes",
                    "created-on": "Creada el",
                    "published-on": "Publicada el",
                    "format": "Formato",
                    "import-file": "Importe un fichero",
                    "import-description": "Arrastre y suelte un fichero de datos para importar su trabajo."
                },
                "work-with-application": {
                    "documents": "Documentos",
                    "decisions": "Decisiones",
                    "model": "Modelo",
                    "description": "Descripción",
                    "type": "Tipo",
                    "function": "Función",
                    "decisionModel": "Modelo de decisión",
                    "open": "Abrir"
                },
                "evaluate-model": {
                    "document": "Documento",
                    "decision": "Decisión",
                    "in": "en"
                },
                "questionnaire": {
                    "input-data": "Datos de entrada",
                    "save": "Guardar",
                    "copy": "Copiar",
                    "raw": "JSON",
                    "form": "Cuestionario",
                    "output-document": "Documento generado",
                    "decision-result": "Resultado",
                    "decision-result-evaldata": "Detalles de la evaluación",
                    "decision-result-time-total": "Tiempo total de computación",
                    "decision-result-time-app-retrieval": "Tiempo de carga de la aplicación",
                    "decision-result-time-input-retrieval": "Tiempo de carga de los datos de entrada",
                    "decision-result-time-decision-eval": "Tiempo de evaluación de la decisión",
                    "decision-result-language-used": "Lenguaje usado",
                    "decision-result-nodes": "Nodos de decisión",
                    "decision-result-internal": "Valores internos",
                    "select-input-file": "Seleccione un fichero de datos",
                    "start": "Inicio",
                    "next": "Reevalua",
                    "result-not-available-initial": "El resultado aún no esta disponible. Por favor, pulse el botón de Inicio.",
                    "result-not-available-nquestions": "El resultado no esta disponible mientras queden preguntas sin responder. Hay al menos $COUNT preguntas pendientes.",
                    "select-a-value": "Seleccioneu una opció",
                    "select-a-type": "Seleccioneu un tipus",
                    "of-type": "De tipus",
                    "true": "si",
                    "false": "no",
                    "empty": "buit",
                    "none": "cap",
                    "none_m": "cap",
                    "none_f": "cap",
                    "at-least": "almenys",
                    "add": "Afegir",
                    "set": "Posar",
                    "one": "un(a)",
                    "decision-explanation-graph": "Grafo de explicación de la decisión",
                    "show": "Mostrar",
                    "ltr": "De izquierda a derecha",
                    "top-bot": "De arriba hacia abajo",
                    "restart": "Reiniciar",
                    "overlay": "Please fix the errors in the input data so that the result can be regenerated."
                },
                "user-settings": {
                    "title": "Parámetros de usuario",
                    "user-id": "Identificador",
                    "email": "Email",
                    "first-name": "Nombre",
                    "language": "Lenguaje",
                    "account-type": "Tipo de cuenta",
                    "company": "Organización",
                    "administrator": "Administrador de usuarios",
                    "billing-access": "Acceso a datos de facturación",
                    "role": "Rol",
                    "level-as-tester": "Nivel como probador"
                },
                "toasts": {
                    "error": {
                        "auth-error": "Fallo de autenticación. Por favor, inténtelo de nuevo.",
                        "load-apps": "Hubo un problema al cargar las aplicaciones. Por favor, inténtelo de nuevo más tarde.",
                        "import-app-version-error": "Formato de número de versión no válido. Debe seguir la convención x.x.x",
                        "import-app-file-type-error": "No es un archivo prdoc válido, falta model.documentPack",
                        "import-app-not-available-for-org":  "No se ha podido cargar el fichero de datos porque su organización no tiene acceso a la aplicación: ",
                        "healthcheck-error": "Se produjo un problema al cargar la comprobación de estado Healthcheck. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-error": "Se produjo un problema al cargar la aplicación. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-load-decision-error": "Se produjo un problema al cargar la decisión. Por favor, inténtelo de nuevo más tarde.",
                        "work-with-app-load-document-error": "Se produjo un problema al cargar el documento. Por favor, inténtelo de nuevo más tarde.",
                        "user-invite-error": "Se produjo un problema al invitar al usuario. Por favor, inténtelo de nuevo más tarde.",
                        "form-load-error": "Hubo un problema al cargar el modelo. Por favor, inténtelo de nuevo más tarde.",
                        "form-evaluation-error": "Se produjo un problema al evaluar el modelo. Por favor, inténtelo de nuevo más tarde.",
                        "form-download-json-error": "Error al descargar los datos en formato JSON. Por favor, inténtelo de nuevo.",
                        "form-download-pdf-error": "Se produjo un problema al descargar el archivo PDF. Por favor, inténtelo de nuevo.",
                        "form-download-msword-error": "Se produjo un problema al descargar el archivo de MS Word. Por favor, inténtelo de nuevo.",
                        
                    },
                    "success": {
                        "load-apps-success": "Aplicaciones cargadas correctamente.",
                        "user-invite-success": "Usuario invitado correctamente.",
                        "form-language-success": "Idioma de entrada y de salida: "
                    },
                    "warning": {
                        "form-language-mismatch": "Su idioma favorito no está disponible en esta App. Usaremos el idioma por defecto de la aplicación en su lugar: ",
                        "drag-drop-cases-warning": "Este documento tiene referencias externas y es un documento de caso. Navengando a Expedientes."
                    }
                },
            },
            "fr": {
                "top-menu": {
                    "file": {
                        "workspace": "Mon espace",
                        "applications": "Mes applications",
                        "marketplace": "Marketplace"
                    },
                    "view": {
                        "item": "Affichage",
                        "interaction": {
                            "header": "Mode d'interaction",
                            "conversational": "Conversationnel",
                            "block": "Questions par bloc",
                            "all": "Voir toutes les questions"
                        },
                        "questions": {
                            "header": "Questions",
                            "on-one-line": "Sur une ligne",
                            "evaluation": "Evaluation",
                            "automatic-reevaluation": "Automatique"
                        },
                        "language": {
                            "keep-in-sync": "Synchroniser les languages",
                            "in": "Language d´entrée",
                            "out": "Langage de sortie"
                        },
                        "preferences": {
                            "header": "Preferences",
                            "model-as-cards": "Vue des modèles en fiche",
                            "view-test-releases": "Voir les versions de test"
                        }
                    },
                    "manage": {
                        "item":  "Gestion",
                        "users": "Utilisateurs",
                        "healthcheck": "État du système"
                    },
                    "search": "Rechercher...",
                    "user": {
                        "item":  "Utilisateur",
                        "settings": "Paramètres de l'utilisateur",
                        "get-token": "Obtenir un jeton d'accès",
                        "logout": "Sortir"
                    }
                },
                "workspace": {
                    "title": "Mon espace",
                    "favorite-folders": "Mes favoris",
                    "all-folders": "Tous mes dossiers"
                },
                "cases": {
                    "title": "Me dossiers",
                    "click-to-open": "Cliquer pour ouvrir",
                },
                "available-applications": {
                    "title": "Mes applications",
                    "releases": "Versions",
                    "patches": "Patchs",
                    "open": "Ouvrir",
                    "by": "Par",
                    "languages": "Langages",
                    "created-on": "Créée le",
                    "published-on": "Publiée le",
                    "format": "Format",
                    "import-file": "Importer un fichier",
                    "import-description": "Glisser et déposer un fichier de données pour importer votre travail."
                },
                "work-with-application": {
                    "documents": "Documents",
                    "decisions": "Décisions",
                    "model": "Modèle",
                    "description": "Description",
                    "type": "Type",
                    "function": "Fonction",
                    "decisionModel": "Modèle de décision",
                    "open": "Ouvrir"
                },
                "evaluate-model": {
                    "document": "Document",
                    "decision": "Décision",
                    "in": "dans"
                },
                "questionnaire": {
                    "input-data": "Données d´entrée",
                    "save": "Sauver",
                    "copy": "Copier",
                    "raw": "JSON",
                    "form": "Questionnaire",
                    "output-document": "Document généré",
                    "decision-result": "Résultat",
                    "decision-result-evaldata": "Détails de l'évaluation",
                    "decision-result-time-total": "Temps total de calcul",
                    "decision-result-time-app-retrieval": "Temps de chargement de l'application",
                    "decision-result-time-input-retrieval": "Temps de chargement des inputs",
                    "decision-result-time-decision-eval": "Temps d'evaluation de la décision",
                    "decision-result-language-used": "Langage utilisé",
                    "decision-result-nodes": "Noeuds de décision",
                    "decision-result-internal": "Valeurs internes",
                    "select-input-file": "Choisissez un fichier de données",
                    "start": "Démarrer",
                    "next": "Réévaluer",
                    "result-not-available-initial": "Le résultat n´est pas encore disponible. Veuillez cliquer sur le bouton Démarrer.",
                    "result-not-available-nquestions": "Le résultat n´est pas encore disponible tant qu´il y a des questions sans réponses. Il y a au moins $COUNT questions non répondues.",
                    "select-a-value": "Choisissez une option",
                    "select-a-type": "Choisissez un type",
                    "of-type": "De type",
                    "true": "oui",
                    "false": "non",
                    "empty": "vide",
                    "none": "aucun(e)",
                    "none_m": "aucun",
                    "none_f": "aucune",
                    "at-least": "au moins",
                    "add": "Ajouter",
                    "set": "Assigner",
                    "one": "un(e)",
                    "decision-explanation-graph": "Graphe d'explication de la décision",
                    "show": "Montrer",
                    "ltr": "De droite à gauche",
                    "top-bot": "Du haut vers le bas",
                    "restart": "Redémarrer",
                    "overlay": "Please fix the errors in the input data so that the result can be regenerated."
                },
                "user-settings": {
                    "title": "Paramètres de l'utilisateur",
                    "user-id": "Identifiant",
                    "email": "Email",
                    "first-name": "Prénom",
                    "language": "Langue",
                    "account-type": "Type de compte",
                    "company": "Organisation",
                    "administrator": "Administrateur d'utilisateurs",
                    "billing-access": "Accès aux données de facturation",
                    "role": "Rôle",
                    "level-as-tester": "Niveau comme testeur"
                },
                "toasts": {
                    "error": {
                        "auth-error": "Erreur lors du login. Veuillez réessayer.",
                        "load-apps": "Problème pour charger les applications. Veuillez réessayer plus tard.",
                        "import-app-version-error": "Le numéro de version n'est pas valide. Il devrait avoir un format x.x.x",
                        "import-app-file-type-error": "Ceci n'est pas un fichier prdoc valide. Il manque model.documentPack",
                        "import-app-not-available-for-org": "Nous ne pouvons pas charger ce fichier de données car votre organisation n'a pas accès à l'application: ",
                        "healthcheck-error": "Un problème a eu lieu lors de la charge des données sur l'état du système. Veuillez réessayer plus tard.",
                        "work-with-app-error": "Un problème a eu lieu lors de la charge de l'application. Veuillez réessayer plus tard.",
                        "work-with-app-load-decision-error": "Un problème a eu lieu lors de la charge de la décision. Veuillez réessayer plus tard.",
                        "work-with-app-load-document-error": "Un problème a eu lieu lors de la charge du document. Veuillez réessayer plus tard.",
                        "user-invite-error": "Un problème a eu lieu lors du process d'invitation. Veuillez réessayer plus tard.",
                        "form-load-error": "Un problème a eu lieu lors de la charge du modèle. Veuillez réessayer plus tard.",
                        "form-evaluation-error": "Un problème a eu lieu lors de l'évaluation du modèle.. Veuillez réessayer plus tard.",
                        "form-download-json-error": "Un problème a eu lieu lors du download du fichier de données. Veuillez réessayer.",
                        "form-download-pdf-error": "Un problème a eu lieu lors du download du fichier PDF. Veuillez réessayer.",
                        "form-download-msword-error": "Un problème a eu lieu lors du download du fichier Word (docx). Veuillez réessayer."
                    },
                    "success": {
                        "load-apps-success": "Succès! Les applications ont été chargées.",
                        "user-invite-success": "Succès! L'utilisateur a été invité.",
                        "form-language-success":  "Langue utilisée en entrée et en sortie: "
                    },
                    "warning": {
                        "form-language-mismatch": "Votre langue préférée n´est pas supportée par cette App. Nous utiliserons la langue par défaut de l'application à la place: ",
                        "drag-drop-cases-warning": "Ce document a des références externes et est un document de cas. Redirection vers les cas."
                    }
                },
            }
        }
    },
    // can be seen as computed properties that can be shared across components
    getters: {
        uiLanguage: state => {
            return state.uiLanguage;
        },
        translate: state => (assetId) => {

            let json = state.i18n[state.uiLanguage];
            var res = assetId.split('.').reduce((o, k) => {
                return o && o[k];
              }, json);
//            console.log("translate '" + assetId + "' to " + state.uiLanguage + " = " + res);
            return res;
        },
        translateTo: state => (assetId, lang) => {
            let json = state.i18n[lang];
            var res = assetId.split('.').reduce((o, k) => {
                return o && o[k];
              }, json);
//            console.log("translateTo '" + assetId + "' to " + lang + " = " + res);
            return res;            
        }
    },
    // logic to change state. must be synchronous. not to be called directly (best practices is to go thru an action)
    mutations: {     
        setUILanguage(state, lang) {
            state.uiLanguage = lang;
        },
        initialize(state, i18n) {
            state.i18n = JSON.stringify(i18n);
            state.languages = Object.keys(i18n);
        }
    },    
    // actions are allowed to run asynchronous code, e.g. make an HTTP call and wait for a response
    actions: {
        setUILanguage: (context, lang) => {
            context.commit('setUILanguage', lang);
        },
        initialize: (context) => {
//            import('@/assets/i18n.json')
//            .then(res => context.commit('initialize', res.default));
        }
    }
}
