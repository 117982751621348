import { defineStore } from 'pinia';

interface FileObject {
  path: any;
  file: any;
}

interface DirectoryObject {
  directory: any;
}

interface State {
  currentFile: FileObject | null;
  isCase: boolean;
  casesOpen: boolean;
  rootFolder: any;
  files: FileObject[] | null;
  directories: DirectoryObject[] | null;
  externalRefs: any;
}

export const useFileStore = defineStore({
  id: 'fileStore',
  state: (): State => ({
    currentFile: null as FileObject | null,
    isCase: false,
    casesOpen: false,
    rootFolder: null as any,
    files: null as FileObject[] | null,
    directories: null as DirectoryObject[] | null,
    externalRefs: null as any,
  }),
  actions: {
    setCurrentFile(file: FileObject) {
      this.currentFile = file;
    },
    setCase(isCase: boolean) {
      this.isCase = isCase;
    },
    setCasesOpen(casesOpen: boolean) {
      this.casesOpen = casesOpen;
    },
    addFile(fileHandle, path) {
      this.files.push({ path: path, file: fileHandle });
    },
    addDirectory(directoryHandle) {
      this.directories.push(directoryHandle);
    },
    resetFileAndDirectory() {
      this.files = [];
      this.directories = [];
      this.currentFile = null;
    },
    resetState() {
      this.casesOpen = false;

    },

    getFileFromStore(filename, path) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file.name === filename && this.files[i].path === path) {
          return this.files[i];
        }
      }
    },
    async readFileContent(fileHandle) {
      console.log(fileHandle);
      const file = await fileHandle.getFile();
      const contents = await file.text();
      return contents;
    },
  },
});
