export const routes = [
    {
        path: '/apps',
        name: 'Apps',
        component: () => import('../pages/apps.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/marketplace',
        name: 'visitMarketplaceSoon',
        component: () => import('../pages/marketplace.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/case',
        name: 'Case',
        component: () => import('../pages/cases/index.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/system-status',
        name: 'SystemStatus',
        component: () => import('../pages/system-status.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/manage-users',
        name: 'UserManagement',
        component: () => import('../pages/manage-users.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/user-settings',
        name: 'UserSettings',
        component: () => import('../pages/user-settings.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/invite-user',
        name: 'InviteUser',
        component: () => import('../pages/invite-user.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
      path: '/edit-user',
      name: 'EditUser',
      component: () => import('../pages/edit-user.vue'),
      meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/manage-organizations',
        name: 'OrganizationManagement',
        component: () => import('../pages/manage-organizations.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/onboard-organization',
        name: 'OnboardOrganization',
        component: () => import('../pages/onboard-organization.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/add-an-application',
        name: 'AddAnApplication',
        component: () => import('../pages/add-an-application.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },    
    {
        path: '/add-a-release/:appId',
        name: 'AddARelease',
        component: () => import('../pages/add-a-release.vue'),
        meta: { requiresAuth: true, hasFooter: true }
    },    
    {
        path: '/edit-organization',
        name: 'EditOrganization',
        component: () => import('../pages/edit-organization.vue'),
        meta: { requiresAuth: true, hasFooter: true }
      },
    {
        path: '/:account/work-with-app/:app/:release/',
        name: 'workWithApplication',
        component: () => import('../components/WorkWithApplication.vue'),
        props: true,
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/:account/apps/:appName/:release/:patch/openapi',
        name: 'showAPIs',
        component: () => import('../components/ApplicationAPIView.vue'),
        props: true,
        meta: { requiresAuth: true, hasFooter: true }
    },
    {
        path: '/:account/work-with-app/:appName/:appVersion/decision/:modelId',
        name: 'evaluateDecision',
        component: () => import('../components/interactive/EvaluateModel.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            hasFooter: false
        }
    },
    {
//        path: '/:account/work-with-app/:appName/:appVersion/document/:docPack/:modelId/:inputs',
        path: '/:account/work-with-app/:appName/:appVersion/document/:docPack/:modelId',
        name: 'evaluateDocument',
        component: () => import('../components/interactive/EvaluateModel.vue'),
        props: true,
//        props: route => ({ inputs: route.params.inputs }),
        meta: {
            requiresAuth: true,
            hasFooter: false
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('../pages/signin.vue'),
        meta: { requiresAuth: false, hasFooter: true }
    },
    /*
    {   Currently not in use
        path: '/openCase',
        name: 'openCase',
        component: () => import('../components/OpenCase.vue'),
        meta: { requiresAuth: true }
    },
    {   Old deprecated login
        path: '/login',
        name: 'UserAuth',
        component: () => import('../components/pages/UserSignin.vue'),
        meta: { requiresAuth: false }
    },

    {   Potential future signup page
        path: '/userSignUp',
        name: 'userSignUp',
        component: () => import('../components/pages/UserSignUp.vue'),
        meta: { requiresUnauth: true }
    },
    */
    /*   Pages not currently active
    {
        path: '/editApplication',
        name: 'editApplication',
        component: EditApplication,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/editRelease',
        name: 'editRelease',
        component: EditRelease,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/editPatch',
        name: 'editPatch',
        component: EditPatch,
        props: true,
        meta: { requiresAuth: true }
    },
    {   Future marketplace
        path: '/visitMarketplace',
        name: 'visitMarketplace',
        component: () => import('../components/VisitMarketplace.vue'),
        meta: { requiresAuth: true }
    },
    */
];
