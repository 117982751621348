/*
 * Module containing shared functions
 */

// remove the portion after the last ':'
export const typeStringFun = (t) => {
//  console.log('t=' + t, 'typeString(t)=' + t.substring(t.lastIndexOf(':') + 1, t.length));
  return t.substring(t.lastIndexOf(':') + 1, t.length);
}

/**
 * Returns an entity type name without the package prefix.
 *
 * @param {number} fqn A string denoting the fully qualified name of an entity type, e.g. 'com.legal.Contract'
 * @return {number} the type name without the package prefix, i.e. 'Contract'
 */
export const entitySimpleNameFun = (fqn) => {
  return fqn.substring(fqn.lastIndexOf('.') + 1, fqn.length)
}

export const elementTypeFun = (collectionTypeStr) => {
  return collectionTypeStr.substring(collectionTypeStr.indexOf(':') + 1, collectionTypeStr.length);
}

export const isWrapperFun = (typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && ("Wrapper" == theType.category);
}
export const isEnumFun = (typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && ("Enum" == theType.category);
}
export const isStructFun = (typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && ("Struct" == theType.category);
}
export const isEntityFun = (typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && ("Entity" == theType.category);
}
export const isObjectFun =(typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && (("Struct" == theType.category) 
                                        || ("Entity" == theType.category));  
}
export const isConcreteObjectFun =(typeName, dataSchema) => {
  let theType = dataSchema[typeName];
  return theType && theType.category && (("Struct" == theType.category) 
                                        || ("Entity" == theType.category && theType.concreteSubTypes.length === 0));  
}

/**
 * This function modifies the instance 'inst' by adding the "required" members described in the dataSchema for its type (instanceType). 
 * Missing members are indicated by a null value.
 */
export const addMandatoryMembersRec = (inst, instanceTypeName, dataSchema) => {
  let instanceType = dataSchema[instanceTypeName]; // the type of the instance

  // we iterate on each member of instanceType
  instanceType.members.forEach(member => {
      if (isObjectFun(member.type, dataSchema) && member.relationshipType == "has") {
          if (member.multiplicity == "1") {
              if (isConcreteObjectFun(member.type, dataSchema)) {
                  var newInst = {
                      __type__: member.type
                  };
                  inst[member.name] = newInst;
                  if (member.required)
                      addMandatoryMembersRec(newInst, member.type, dataSchema);
              }
              else if (member.required) { // not a concrete object type but required => we set the __typeDomain__ field
                  var newInst = {
                      __type__: member.type,
                      __typeDomain__ : member.type
                  };
                  inst[member.name] = newInst;
              }
          }
          else if (member.required) {
              if (member.multiplicity == "0..1" || member.multiplicity == "0+" || member.multiplicity == "1+") {
                  inst[member.name] = null; // missing value;
              }
          }
      }
      else if (member.required) {
          inst[member.name] = null;
        }
  });
  if (instanceType.parent) {
      addMandatoryMembersRec(inst, instanceType.parent, dataSchema);
  }
  return;
}