<template>
  <footer>
    <div class="container">
      <div class="left-container">
        <router-link to="/">
          <img
            class="mobile-logo"
            src="/images/provingly_logo_white.svg"
            alt="Provingly"
            width="150"
          >
          <img
            class="logo"
            src="/images/provingly_footer_logo.svg"
            alt="Provingly"
            height="25"
          >
        </router-link>
        <div class="copyright">
          Provingly.io © {{ new Date().getFullYear() }}. All Rights Reserved – With ♥ by&nbsp;
          <a
            href="https://behappy.co/"
            target="_blank"
          >
            BeHappy
          </a>
        </div>
      </div>
      <ul>
        <li>
          <a
            class="legal-link"
            href="https://www.provingly.io/legal-notice"
            data-cursor-hover
          >
            Legal Notice
          </a>
        </li>
        <b>·</b> <!-- Semantically these are not list items, which is why I used b -->
        <li>
          <a
            href="https://www.provingly.io/privacy-policy"
            data-cursor-hover
          >
            Privacy Policy
          </a>
        </li>
        <b>·</b> <!-- Semantically these are not list items, which is why I used b -->
        <li>
          <a
            href="https://www.provingly.io/cookies-policy"
            data-cursor-hover
          >
            Cookies Policy
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style scoped>
footer {
  margin-top: auto;
  background-color: var(--darker-blue);
  color: var(--secondary-blue);
  height: 45px;
  align-items: center;
  display: flex;
  font-family: "Source Sans Pro";
}

.mobile-logo {
  display: none;
}

.container {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
}

.left-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding-right: 0.5rem;
  align-items: center;
  min-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: var(--secondary-blue);
}

@media screen and (max-width: 1000px) {
  footer {
    height: 150px;
  }

  .container {
    flex-direction: column;
    font-size: 13px;
  }

  .left-container {
    flex-direction: column;
    padding-bottom: 0.5rem;
  }

  ul {
    padding-inline-start: 0;
  }

  .logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }
}
</style>
