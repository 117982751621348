<template>
  <li
    class="nav-item"
    @click="handleClick"
  >
    <router-link
      :data-testid="props.cyData"
      class="dropdown-item app-nav header-nav"
      :to="props.target"
      :class="{ active: props.isActive }"
    >
      {{ props.text }}
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
  target: String,
  text: String,
  cyData: String,
  isActive: Boolean,
});

const emit = defineEmits(['click']);

const handleClick = () => {
  emit('click');
}
</script>
<style scoped>
.header-nav {
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 600;
  padding-inline: 1rem;
  color: var(--dark-title);
}
li.nav-item .active {
  background-color: white;
  border-bottom: 4px solid var(--brand-blue);
}
.app-nav {
  padding-inline: 0.25rem !important;
}
</style>
