import { createI18n } from 'vue-i18n';

import en_messages from '@/locales/en.json';
import es_messages from '@/locales/es.json';
import fr_messages from '@/locales/fr.json';

const messages = {
  en: en_messages,
  es: es_messages,
  fr: fr_messages
}

const options = {
  legacy: false,
  //locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
}

// Workaround for using translation in Options API scripts
export const trans = (key,values) => {
  if (!key) {
      return '';
  }
  return i18n.global.t(key, values);
};

export const i18n = createI18n(options);
