import { createApp, markRaw } from 'vue'
import {createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'
import { routes } from './router/routes.js';
import { store } from './store/store.js';
import { createPinia } from 'pinia'
import { useUserStore } from './stores/auth'
import VNetworkGraph from "v-network-graph"
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { useI18n } from 'vue-i18n'

import { i18n } from '@/plugins/i18n';

import 'bootstrap'
import './assets/styles/index.scss'

const app = createApp({
  setup() {
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    return { t } // return render context that included `t`
  },
  ...App
});

const router = createRouter({
  history: createWebHistory(),
  routes
});

const pinia = createPinia()
// Access the router in the Pinia Store
pinia.use(({ store }) => { store.router  = markRaw(router) });
pinia.use(createPersistedState({
  storage: sessionStorage,
  auto: true,
}))

app.use(store)
app.use(pinia)
app.use(i18n)
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  timeout: 4000,
  hideProgressBar: true,
  position: "top-right",
});
app.use(router);
app.use(VNetworkGraph)


const main = useUserStore(pinia)
i18n.global.locale.value = main.preferredLanguage
router.beforeEach(async (to, from, next) => {
  //await main.refreshJWT()
  await main.checkAutoLogin()
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.meta.requiresAuth && !main.isLoggedIn) {
    // console.log("trying to access page that requires authentication without being logged in");
    //next(`/?redirect=${to.path.substring(1)}`);
    next("/");
  } 
  else if (!to.meta.requiresAuth && main.isLoggedIn) {
    // console.log("trying to access page that does not require authentication while being logged in");
    next('/apps');
  }
  else {
    // console.log("Navigating to route: " + to.path);
    next();
  }
})

app.mount("#app")

if (window.Cypress) {
  window.store = { main };
}
