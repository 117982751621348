<script setup>
import { useUserStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue'
import SettingsIcon from '../svgs/SettingsIcon.vue'
import HeaderLinks from './HeaderLinks.vue';
import { useToast } from "vue-toastification";
const toast = useToast()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const userStore = useUserStore()
const route = useRoute()
const currentRoute = computed(() => route.path);

const myAppsActive = ref(true)
const notifications = ref(false)
const {
  token,
  userId,
  accountType,
  profile,
} = storeToRefs(userStore);

console.log(t)

const copyToken = (tok) => {
  navigator.clipboard.writeText(tok);
  toast.success(t('top-menu.user.token-copied'))
}
</script>

<template>
  <nav class="navbar navbar-expand-sm navbar-primary app-header">
    <div>
      <router-link
        class="navbar-brand"
        aria-label="Header home page link"
        to="/apps"
      >
        <img
          src="/images/provingly_logo.svg"
          style="height:35px;"
        >
      </router-link>
    </div>
    <div class="collapse navbar-collapse">
      <ul
        class="navbar-nav mr flex-fill justify-content-center gap-3"
      >
        <HeaderLinks
          v-if="profile.accessExperimentalFeatures"
          target="/case"
          :text="t('cases.title')"
          cyData="cases-button"
          :isActive="currentRoute === '/case'"
        />
        <HeaderLinks
          target="/apps"
          :text="t('top-menu.file.applications')"
          cyData="myapps-button"
          :isActive="currentRoute === '/apps'"
        />
        <HeaderLinks
          target="/marketplace"
          :text="t('top-menu.file.marketplace')"
          cyData="marketplace-button"
          :isActive="currentRoute === '/marketplace'"
        />
      </ul>
    </div>
    <div class="grid-container">
      <div class="dropdown-container">
        <ul
          class="navbar-nav mr-auto"
          >
            <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  >

                <div v-if="!notifications">
                    <img
                      src="/images/notification-inactive.svg"
                      alt=""
                      >
                </div>
                <div v-if="notifications">
                    <img
                      src="/images/notification-active.svg"
                      alt=""
                      >
                    <img
                      src="/images/notification_badge.svg"
                      alt=""
                      style="position:absolute; top:6px;left:4px; height:13px"
                      >
                    <span
                      style="font-size:10px;font-weight: 600; position:absolute;top:5px;left:8px;color:white"
                      >
                      1
                    </span>
                </div>
                </a>
                <div
                  class="dropdown-menu"
                  style="left: -16rem;"
                  >
                    <li class="dropdown-item-text">
                        <strong style="font-size: 14px;">Notifications</strong>
                    </li>
                    <div class="dropdown-divider"></div>
                    <li class="nav-item dropdown-item-text">
                        There is a new version of the app available
                    </li>
                </div>
            </li>
        </ul>
        <ul
          class="navbar-nav mr-auto"
        >
          <div class="nav-item dropdown">
            <a
              data-testid="admin-dropdown-button"
              id="navbarDropdown"
              class="nav-link settings-link dropdown-toggle"
              role="button"
              aria-expanded="false"
              aria-label="Admin dropdown"
              data-bs-toggle="dropdown"
            >
              <SettingsIcon />
            </a>
            <ul
              class="dropdown-menu"
              style="left: -10rem;"
            >
              <li class="dropdown-item-text">
                <strong style="font-size: 14px;">{{ t('top-menu.manage.system-settings') }}</strong>
              </li>
              <div class="dropdown-divider" />
              <li
                v-if="accountType == 'company'"
                class="nav-item"
              >
                <router-link
                  v-if="profile.isAdmin"
                  data-testid="manage-users-button"
                  aria-label="Manage users"
                  class="dropdown-item"
                  to="/manage-users"
                >
                  {{ t('top-menu.manage.users') }}
                </router-link>
              </li>

              <li
                v-if="accountType == 'company'"
                class="nav-item"
              >
                <router-link
                  v-if="profile.mainRole === 'owner' && profile.companyId === 'Provingly'"
                  data-testid="manage-organizations-button"
                  aria-label="Manage organizations"
                  class="dropdown-item"
                  to="/manage-organizations"
                >
                {{ t('top-menu.manage.organizations') }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  data-testid="system-status-button"
                  aria-label="System status"
                  class="dropdown-item"
                  to="/system-status"
                >
                  {{ t('top-menu.manage.healthcheck') }}
                </router-link>
              </li>
            </ul>
          </div>
        </ul>

        <ul
          v-if="profile.accessExperimentalFeatures"
          class="navbar-nav"
        >
          <div class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Help
            </a>
            <ul class="dropdown-menu dropdown-menu-right" style="left: -10rem;">
              <a
                class="dropdown-item"
                href="#"
                @click="documentation"
              >
                Documentation
              </a>
              <div class="dropdown-divider" />
              <a
                class="dropdown-item"
                href="#"
                @click="reportIssue"
              >
                Report issue
              </a>
              <div class="dropdown-divider" />
              <a
                class="dropdown-item"
                href="#"
                @click="whatIsNew"
              >
                What's new
              </a>
              <a
                class="dropdown-item"
                href="#"
                @click="about"
              >
                About
              </a>
            </ul>
          </div>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              data-testid="user-dropdown"
              aria-label="User dropdown"
              class="nav-link username-button dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <!-- Should be moved to a component (UserIcon.vue), but I couldnt get it to load from there at the moment. Used a default value of "U" when User is not logged in. -->
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12.5"
                  fill="var(--brand-blue)"
                />
              </svg>
              <p
                class="username-text"
                text-anchor="middle"
              >
                {{ userId.slice(0, 1).toUpperCase() }}
              </p>
            </a>
            <div
              class="dropdown-menu"
              style="left: -10rem;"
            >
              <li class="dropdown-item-text">
                <strong>{{ profile.firstName }}</strong>
              </li>
              <div class="dropdown-divider"/>
              <li class="nav-item">
                <router-link
                  data-testid="user-settings-button"
                  aria-label="User settings"
                  class="dropdown-item"
                  to="/user-settings"
                >
                  {{ t('top-menu.user.settings') }}
                </router-link>
              </li>
              <a
                class="dropdown-item"
                href="#"
                @click="copyToken(token)"
              >
                {{ t('top-menu.user.get-token') }}
              </a>
              <div class="dropdown-divider"/>
              <a
                data-testid="logout-button"
                class="dropdown-item"
                href="#"
                @click="userStore.logout"
              >
                <strong>{{ t('top-menu.user.logout') }}</strong>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import { useUserStore } from '@/stores/auth'
import { mapState } from 'pinia'

export default {
  computed: {
    //...mapGetters(['translate']),
    // ...mapState(useUserStore, ['token'])
  },
}
</script>
<style scoped>
.app-header {
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: 1fr 70% 1fr;
  min-height: 3rem;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-brand {

  font-size: 24px;
}

.navbar-brand img {
  height: 30px;
  /* put value of image height as your need */

}
.grid-container {
  display: grid;
  place-items: end;
}
.dropdown-container {
  display: flex;
  right: 0;
}


.content {
  min-height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
}

.username-text {
  color: white;
  position: absolute;
  top: 9px;
  /* left: 13px; */
  font-weight: 600;
  margin-bottom: 0 !important;
  width: 25px;
  text-align: center;
  height: 25px;
}

.username-button {
  display: grid;
}

</style>
