/*
 * Vuex store module to manage applications
 */
import { typeStringFun,
    entitySimpleNameFun } from '../../common/schemaUtilities';

import * as Vue from 'vue';
import { useUserStore } from '../../stores/auth';

// Local function
// It transforms an expression string for the refname name into an array of atomic elements, each element being either a string literal or an attribute name.
const transformExp = (expString) => {
    var expressionParts = [];
    var current = expString.trim();
    while (current.length > 0) {
        if (current[0] === "+") { // pass the plus sign
            current = current.substring(1);
        }
        else if (current[0] === "'") {
            current = current.substring(1);
            let nextQuoteIndex = current.indexOf("'");
            let strLiteral = current.substring(0, nextQuoteIndex);
            expressionParts.push({
                type: "literal",
                exp: strLiteral
            });
            current = current.substring(nextQuoteIndex+1);
        }
        else if (current.startsWith("this.")) {
            current = current.substring(5);
            let plusIndex = current.indexOf("+");
            if (plusIndex == -1) {
                expressionParts.push({
                    type: "attr",
                    exp: current
                });
                current = "";
            }
            else {
                expressionParts.push({
                    type: "attr",
                    exp: current.substring(0, plusIndex)
                });
                current = current.substring(plusIndex+1);
            }
        }
        else {
            alert("Error in transformExp " + expString + ", current = " + current);
            current = "";
        }
    }
    return expressionParts;
}

// Local function
// Given expressionParts and an object value, it produces a reference name for the object.
const evaluateRefName = (expressionParts, object) => {
    var result = "";
    var error = null;
    expressionParts.forEach((p) => {
        if (p.type === "literal") {
            result = result + p.exp;
        }
        else if (p.type === "attr") {
            if (object[p.exp])
                result = result + object[p.exp];
            else
                error = p.exp;
        }
    });
//    console.log("evaluateRefName:" + result + ", error = " + error);
    if (error)
        return {
            "status": "nok",
            "result": error
        };
    else
        return {
            "status": "ok",
            "result": result
        };
}

export default {
    state: {
        selectedAppLabel: null,
        selectedApp: null,
        selectedDecision: null,
        selectedDocument: null,
        objectInstances: {},
        usedPack: null,
        inputLanguage: null,
        outputLanguage: null,
        keepLanguagesInSync: true,
        numUpdates: 0,
        viewOverlay: false,
        numUpdates: 0,
        temporaryInputs: null
    },

    // can be seen as computed properties that can be shared across components
    getters: {
        overLayState: state => {
            return state.viewOverlay;
        },
        appVersionInfo: state => {
            console.log(BUILD_ID)
            return {
              buildId: BUILD_ID,
              commit: COMMIT_REF
            };
        },
        numUpdates: state => {
            return state.numUpdates;
        },
        temporaryInputs: state => {
            return state.temporaryInputs;
        },
        inputLanguage: state => {
            return state.inputLanguage;
        },
        outputLanguage: state => {
            return state.outputLanguage;
        },
        keepLanguagesInSync: state => {
            return state.keepLanguagesInSync;
        },
        selectedApp: state => {
            return state.selectedApp;
        },
        isEnum: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            return theType && theType.category && ("Enum" == theType.category);
        },
        isWrapper: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            return theType && theType.category && ("Wrapper" == theType.category);
        },
        isStruct: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            return theType && theType.category && ("Struct" == theType.category);
        },
        isEntity: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            return theType && theType.category && ("Entity" == theType.category);
        },
        isObject: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            return theType && theType.category && (("Struct" == theType.category)
                                                || ("Entity" == theType.category));
        },
        collectionTypeDetails: (state) => (typeName) => {
            console.log("Typename: " + typeName)
            let parts = typeName.split(":");
            if (parts.length > 0) {
                var min = -1;
                var max = 10000;
                if (parts[0] === 'List') {
                    min = 0;
                }
                else if (parts[0] === 'List') {
                    min = 1;
                }
                else if (parts[0] === 'Option') {
                    min = 0;
                    max = 1;
                }
                else {
                    return {
                        collectionType: "noCollection"
                    };
                }

                return {
                    collectionType: parts[1].indexOf(".") !== -1 ? "object": "simple",
                    min: min,
                    max: max,
                    elemType: parts[1]
                };
            }
            else
                return {
                    collectionType: "noCollection",
                };
        },
        isConcreteObject: (state) => (typeName) => {
            let theType = state.selectedApp.schema[typeName];
            let result = theType && theType.category && (("Struct" == theType.category)
                                                || ("Entity" == theType.category && theType.concreteSubTypes.length === 0));
//            console.log("isConcreteObject(" + typeName + ")");
//            console.log(result);
            return result;
        },
        betChoices: (state) => (entityName) => {

            let theBet = state.selectedApp.schema[entityName];
            let concreteTypes = theBet.concreteSubTypes;
            let result = concreteTypes.map(t => ({ value: typeStringFun(t),
                                            text: entitySimpleNameFun(t)}));
//            console.log("betChoices(" + entityName + ")");
//            console.log(result);
            return result;
        },
        getObjectInstances: (state) => (entityName) => {
            let theBet = state.selectedApp.schema[entityName];
            let types = (theBet.concreteSubTypes.length === 0) ? [entityName] : theBet.concreteSubTypes.concat(entityName);
            let instances = types.
                    flatMap(t => state.objectInstances[t]
                        ? state.objectInstances[t].map(e => { return {"objectRef": e.path, "label": (e.refName ? e.refName : e.refId)};})
//                        ? state.objectInstances[t].map(e => { return {"objectRef": e.path, "label": (e.refName ? e.refName : e.refId) +  " (" + entitySimpleNameFun(t) + ")"};})
                        : []).
                    sort((l, r) => l.objectRef.localeCompare(r.objectRef));
            console.log("getObjectInstances for " + entityName + JSON.stringify(instances));
            return instances;
        },
        allObjectInstances: state => {
            return state.objectInstances;
        },
        enumChoices: (state) => (enumName) => {
            let theEnum = state.selectedApp.schema[enumName];
            return theEnum.values.map(t => ({ value: t,
                                              text: t}));
        },
        selectedDecision: state => {
            return state.selectedDecision;
        },
        selectedDocument: state => {
            return state.selectedDocument;
        },
        usedPack: state => {
            return state.usedPack;
        },
        selectedModel: state => {
            return (state.selectedDecision !== null) ? state.selectedDecision : state.selectedDocument;
        },
        paramTypes: state => {
            let result = {};
            let selectedModel = (state.selectedDecision !== null) ? state.selectedDecision : state.selectedDocument;
            selectedModel.inputs.map(param =>
                result[param.name] = param.type
            )
            return result;
        },
        appName: state => {
            return state.selectedApp.name;
        },
        appLabel: state => {
            return state.selectedAppLabel;
        },
        appVersion: state => {
            return state.selectedApp.actualVersion;
        },
        entityWords: (state) => (entityTypeName) => {
//            console.log("entityWords " + entityTypeName);

            let lastDotIndex = entityTypeName.lastIndexOf('.');
            let packageName = entityTypeName.substring(0, lastDotIndex);
            let objectTypeSimpleName = entityTypeName.substring(lastDotIndex+1, entityTypeName.length);
//            console.log("entityWords " + objectTypeSimpleName + "." + member.name + " in pack " + packageName);

            // Default label in the absence of localization info
            let entityLabel = objectTypeSimpleName;

            if (state.selectedApp.localization.data[state.inputLanguage]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName]) {
                entityLabel = state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName];
            }
            return entityLabel;
        },
        optionWords: (state) => (enumType, option) => {
//            console.log("optionWords " + JSON.stringify(enumType, null, "  ") + ", " + option);
            // Default label in the absence of localization info
            let optionLabel = option;

            let lastDotIndex = enumType.type.lastIndexOf('.');
            let packageName = enumType.type.substring(0, lastDotIndex);
            let enumTypeSimpleName = enumType.type.substring(lastDotIndex+1, enumType.type.length);


            if (state.selectedApp.localization.data[state.inputLanguage]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName][enumTypeSimpleName + "." + option]) {
                optionLabel = state.selectedApp.localization.data[state.inputLanguage][packageName][enumTypeSimpleName + "." + option];
            }
            return optionLabel;
        },
        constructorError: (state) => (objectTypeName) => {
            console.log("constructorError " + objectTypeName);
            let lastDotIndex = objectTypeName.lastIndexOf('.');
            let packageName = objectTypeName.substring(0, lastDotIndex);
            let objectTypeSimpleName = objectTypeName.substring(lastDotIndex+1, objectTypeName.length);

            var error = "Input value is not valid";
            if (state.selectedApp.localization.data[state.inputLanguage]
            && state.selectedApp.localization.data[state.inputLanguage][packageName]
            && state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "#error"]) {
                error = state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "#error"];
            }
            return error;
        },
        memberWords: (state) => (parentObjectTypeName, member, what) => {
            //console.log("memberWords " + JSON.stringify(member, null, "  ") + ", what = " + what + ", parentObjectTypeName = " + parentObjectTypeName);
            // member.name might actually be defined on a parent class of parentObjectTypeName
            var theObjectTypeName = parentObjectTypeName;
            while (!state.selectedApp.schema[theObjectTypeName].members.find(elt => elt.name == member.name)) {
                theObjectTypeName = state.selectedApp.schema[theObjectTypeName].parent;
            };

            let lastDotIndex = theObjectTypeName.lastIndexOf('.');
            let packageName = theObjectTypeName.substring(0, lastDotIndex);
            let objectTypeSimpleName = theObjectTypeName.substring(lastDotIndex+1, theObjectTypeName.length);
//            console.log("memberWords " + JSON.stringify(member, undefined, 2) + " on " + objectTypeSimpleName + "." + member.name + " in pack " + packageName);

            // Default label in the absence of localization info
            let memberLabel = (what == 'name') ? member.name : member.singular;

            if (what == 'name'
                    && state.selectedApp.localization.data[state.inputLanguage]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name]) {
                memberLabel = state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name];
            }
            else if (what == 'singular'
                    && state.selectedApp.localization.data[state.inputLanguage]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name+"#sing"]) {
                memberLabel = state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name+"#sing"];
            }
            else if (what == 'error'
                    && state.selectedApp.localization.data[state.inputLanguage]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName]
                    && state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name+"#error"]) {
                memberLabel = state.selectedApp.localization.data[state.inputLanguage][packageName][objectTypeSimpleName + "." + member.name+"#error"];
            }
            return memberLabel;
        },

        // TODO: consider moving this outside of vuex store
        postprocessRequest: (state) => (obj) => {
            // clone the instances and recursively remove: null properties, errors, ...
            return JSON.parse(JSON.stringify(obj, (k,v) => (k === '__errors__' || v === null)? undefined : v));
        }
    },
    // logic to change state. must be synchronous. not to be called directly (best practices is to go thru an action)
    mutations: {
        setOverlay: (state, overlay) => {
            state.viewOverlay = overlay;
        },
        incrNumUpdates: state => {
            state.numUpdates += 1;
            console.log("Store numUpdates = " + state.numUpdates);
        },
        setTemporaryInputs: (state, tempInputs) => {
            state.temporaryInputs = tempInputs;
        },
        setInputLanguage: (state, lang) => {
//            if (state.selectedApp.languages.indexOf(lang) == -1) // might occur if user preferred language is not supported by the app
//                return;

            state.inputLanguage = lang;
            if (state.keepLanguagesInSync)
                state.outputLanguage = lang;
        },
        setOutputLanguage: (state, lang) => {
//            if (state.selectedApp.languages.indexOf(lang) == -1)  // might occur if user preferred language is not supported by the app
//                return;
            console.log(lang);
            state.outputLanguage = lang;
            if (state.keepLanguagesInSync)
                state.inputLanguage = lang;
        },
        switchKeepLanguagesInSync: (state) => {
            state.keepLanguagesInSync = !state.keepLanguagesInSync;
        },
        setApp: (state, application) => {
            console.log("Mutation: setting selectedApp to " + JSON.stringify(application.name + " " + application.actualVersion));
            if (state.selectedApp != application) {
                state.selectedApp = application;
                state.inputLanguage = application.languages[0];
                state.outputLanguage = application.languages[0];
                state.usedPack = null;
                state.selectedDocument = null;
                state.selectedDecision = null;
                state.numUpdates = 0;

            }
        },
        setDecision: (state, decision) => {
            console.log("Mutation: setting selectedDecision to " + JSON.stringify(decision.name));
            state.selectedDecision = decision;
            state.usedPack = null;
            state.selectedDocument = null;
            state.objectInstances = {};
            state.numUpdates = 0;
        },
        setDocument: (state, payload) => {
            console.log("Mutation: in docpack " + payload.usedPack + " setting selectedDocument to " + JSON.stringify(payload.document.name));
            state.usedPack = payload.usedPack;
            state.selectedDocument = payload.document;
            state.selectedDecision = null;
            state.objectInstances = {};
            state.numUpdates = 0;
            console.log('document set')
        },

        updateRefName: (state, payload) => {
            let objectTypeName = payload.objectValue.__type__;
            let objectPath = payload.objectPath;
            let objectValue = payload.objectValue;
//            console.log("updateRefName " + objectTypeName + ", "+ objectPath + ", " + JSON.stringify(objectValue));

            let objectType = state.selectedApp.schema[objectTypeName];
            if (objectType) {
//                console.log("objectType.members " + JSON.stringify(objectType.members));

                let customRef = objectType.members.filter((m) => {
                    return m.name === "__ref__";
                });
//                console.log("customRef " + JSON.stringify(customRef));

                if (customRef.length > 0) { // there is a custom expression for reference names
                    let exp = customRef[0].exp;
                    let expressionParts = transformExp(exp);
                    let refNameResult = evaluateRefName(expressionParts, objectValue);

//                    console.log("updateRefName. refNameResult" + JSON.stringify(refNameResult));

                    if (refNameResult.status === "ok") { // store the new refName
                        let registeredInstances = state.objectInstances[objectTypeName];
//                        console.log("updateRefName. registeredInstances" + JSON.stringify(registeredInstances));

                        let instancesToMofify = registeredInstances.filter((obj) => {
                            return obj.path === objectPath;
                        })
                        if (instancesToMofify.length > 0) {
                            Vue.set(instancesToMofify[0], "refName", refNameResult.result);
                        }
                    }
                }
            }
        },
        // TODO: when registering an instance, its required members must be included in the payload. This is not currently the case...
        registerObjectInstance: (state, payload) => {
            console.log("registerObjectInstance " + JSON.stringify(payload));
            console.log("registered instances before: " + JSON.stringify(state.objectInstances));
            let objectType = payload.objectType;
            if (!state.objectInstances[objectType]) {
                state.objectInstances[objectType] = [];  // needed for reactivity
//                state.objectInstances = { ...state.objectInstances, objectType: [] };
            }

            // check whether a custom expression is available to compute a reference name
            var refName = null
            let theObjectType = state.selectedApp.schema[objectType];
            if (theObjectType) {
                let customRef = theObjectType.members.filter((m) => {
                    return m.name === "__ref__";
                });
                if (customRef.length > 0) {
                    let exp = customRef[0].exp;

                    // TODO: to be removed. we generate a random string for test purposes
//                    payload.id = (Math.random() + 1).toString(36).substring(7);

                    let expressionParts = transformExp(exp);
                    let refNameResult = evaluateRefName(expressionParts, payload);
                    if (refNameResult.status === "ok")
                        refName = refNameResult.result;
                }
            }

            state.objectInstances[objectType].push({
                "path": payload.path,           // use in JSON payload when calling the API
                "refId": payload.refId,         // how we show a path to users
                "refName": refName              // human-readable name used to identify an instance of type 'objectType'
            });
            console.log("registered instances after: " + JSON.stringify(state.objectInstances));
            console.log("----");
        },
        deregisterObjectInstance: (state, payload) => {
            let objectType = payload.objectType;
            if (state.objectInstances[objectType]) {
                var index = state.objectInstances[objectType].indexOf(payload.path); // TODO: fix this. we used to have a string but now we have an object to match...
                if (index !== -1) {
                    state.objectInstances[objectType].splice(index, 1); // TODO: check if this is reactive
                }
            }
        },
        cleanObjectInstances: (state) => {
            state.objectInstances = {};
        }
    },
    // actions are allowed to run asynchronous code, e.g. make an HTTP call and wait for a response
    actions: {
        setOverlay: (context, payload) => {
            context.commit('setOverlay', payload);
        },
        incrNumUpdates: context => {
            context.commit('incrNumUpdates');
        },
        loadApp: async (context, payload) => {
            console.log("vuex store action - openApp: " + JSON.stringify(payload));
            const store = useUserStore();
            // call API to retrieve application info
            const base = import.meta.env.VITE_BASEURL_EXE;
            const patch = payload.patch != null ? '.' + payload.patch : '';
            const url = `${base}/v1/apps/${payload.appName}/releases/${payload.release}${patch}?account=${payload.accountId}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + store.token
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const appJson = await response.json();

                if (appJson.status && appJson.status == "NOT_FOUND") {
                    throw new Error(`Application not found! status: ${appJson.status}, message: ${appJson.message}`);
                } else {
                    context.commit('setApp', appJson);
                }
            } catch (error) {
                console.error('Looks like there was a problem to GET application from ' + url
                + '\n ERROR= ' + error.message
                + '\n' + "Please verify if Provingly server is started...");
                throw error;
            }
        },
        closeApp: context => { // commit change
            context.commit('setApp', null);
        },
        setTemporaryInputs: (context, payload) => {
            context.commit('setTemporaryInputs', payload);
        },
        selectDecision: (context, payload) => { // commit change
            context.commit('setDecision', payload)
        },
        selectDocument: (context, payload) => { // commit change
            context.commit('setDocument', payload)
        },
        updateRefName: (context, payload) => {
            context.commit('updateRefName', payload);
        },
        registerObjectInstance: (context, payload) => {
            context.commit('registerObjectInstance', payload);
        },
        deregisterObjectInstance: (context, payload) => {
            context.commit('deregisterObjectInstance', payload);
        },
        cleanObjectInstances: context => {
            context.commit('cleanObjectInstances');
        },
        setInputLanguage: (context, lang) => { // commit change
            context.commit('setInputLanguage', lang)
        },
        setOutputLanguage: (context, lang) => { // commit change
            context.commit('setOutputLanguage', lang)
        },
        switchKeepLanguagesInSync: (context) => { // commit change
            context.commit('switchKeepLanguagesInSync')
        }
    }
}
