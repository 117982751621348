import { createStore } from 'vuex'
import applicationModule from './modules/applicationModule';
//import userModule from './modules/userModule';
import viewPrefsModule from './modules/viewPrefsModule';
import localizationModule from './modules/localizationModule';

 
// Our central store is made of several modules
export const store = createStore({
    modules: {
        applicationModule,
        //userModule,
        viewPrefsModule,
        localizationModule
    }
});