/*
UserStore, auth and other user actions
*/
import { Amplify, Auth } from 'aws-amplify';
import { defineStore } from 'pinia'
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Passwordless } from 'amazon-cognito-passwordless-auth'
import { requestSignInLink, signInWithLink } from "amazon-cognito-passwordless-auth/magic-link";
import { i18n } from '../plugins/i18n';
import { useToast } from "vue-toastification";

const toast = useToast()

const amplifyConfig = {
    region: import.meta.env.VITE_COGNITO_REGION,
    aws_user_pools_id: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: import.meta.env.VITE_COGNITO_CLIENT_ID
}

Amplify.configure(amplifyConfig);

Passwordless.configure({
    cognitoIdpEndpoint: import.meta.env.VITE_COGNITO_REGION,
    clientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
})

enum Role {
  GUEST = 'guest',
  HANDLER = 'handler',
  RELEASER = 'releaser',
  OWNER = 'owner'
}

interface Profile {
    firstName: string;
    lastName: string;
    locale: string;
    email: string;
    organization: string;
    mainRole: Role;
    levelAsTester: string;
    companyId: string;
    isAdmin: boolean;
    billingAccess: boolean;
    firstLoginDate: string;
    accessExperimentalFeatures: boolean;
}

interface State {
    cognitoUser: CognitoUser | null;
    userId: string | null;
    email: string | null;
    token: string | null;
    jwtToken: string | null;
    tokenExpiration: string | null;
    profile: Profile | null;
    preferredLanguage: string | null;
    useMockupFeatures: boolean;
    useDeveloperFeatures: boolean;
    supportedLanguages: string[];
    companyUsers: any[];
    userToEdit: any;
}

const initialState:State = {
    cognitoUser: null,
    userId: null,
    email: null,
    token: null,
    jwtToken: null,
    tokenExpiration: null,
    profile: null,
    preferredLanguage: null,
    useMockupFeatures: false,
    useDeveloperFeatures: false,
    supportedLanguages: ['en', 'es', 'fr'],
    companyUsers: [],
    userToEdit: null
}

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            ...initialState
        };
    },
    /* persist state is set in main.js
    persist: {
        sessionStorage: localStorage
    },
    */
    getters: {
        isLoggedIn(): boolean {
            return !!this.token;
        },
        accountType: state => {
            return state.profile?.companyId == null ? 'individual' : 'company';
        },
    },
    actions: {
        async reqSignInLink(email: string) {
            // we pass the email in lower case to make the lookup more robust
            const { signInLinkRequested } = requestSignInLink({ usernameOrAlias: email.toLowerCase() });
            try {
                await signInLinkRequested;
                console.log("We've emailed you a secret sign-in link")
            } 
            catch(err) {
                console.log("Oops", err)
                throw Error(err)

            }
        },
        async fetchCompanyUsers() {
          const baseUrl = import.meta.env.VITE_BASEURL_CUSTOMER_MGT;

          try {
            // Log necessary debug data
            console.log('Base URL:', baseUrl);
            console.log('Company ID:', this.profile.companyId);
            console.log('Fetching users...');

            const response = await fetch(`${baseUrl}/userDetails?orgId=${this.profile.companyId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.token}`
              }
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch with status ${response.status}: ${response.statusText}`);
            }

            const companyUsers = await response.json();
            this.companyUsers = companyUsers;
            console.log('Fetched users:', companyUsers);

          } catch (error) {
            console.error('Error fetching users:', error);
          }
        },
        async refreshJWT() {
            try {
                let session = await Auth.currentSession();
                this.token = session.getIdToken().getJwtToken();

                this.jwtToken = session.getAccessToken().getJwtToken();
                this.tokenExpiration = session.getIdToken().getExpiration();
                console.log("Token refreshed")
            } 
            catch(err) {
                console.log("Error: ", err)
                throw Error(err)
            }
        },
        async checkAutoLogin() {
            const { signedIn } = signInWithLink();
            try {
                await signedIn;
                if (!this.isLoggedIn) {
                    try {
                        console.log('Trying to get current session')
                        // This will throw an error if the user is not yet authenticated:
                        let session = await Auth.currentSession();
                        let idToken = session.getIdToken();
                        console.log("User is logged in")
                        console.log(JSON.stringify(idToken.payload));
                        this.userId = idToken.payload.given_name;       // TODO: check this. userId is NOT the given_name!!
                        this.email = idToken.payload.email;
                        this.profile = {
                            firstName: idToken.payload.given_name,
                            lastName: idToken.payload.family_name,
                            locale: idToken.payload.locale,
                            email: idToken.payload.email,
                            userId: idToken.payload['cognito:username'],
                            organization: idToken.payload.organization,
                            mainRole: idToken.payload.mainRole,
                            firstLoginDate: idToken.payload.firstLoginDate,
                            levelAsTester: idToken.payload.levelAsTester,
                            companyId: idToken.payload.organization,
                            isAdmin: idToken.payload.isUserAdmin === 'true',
                            billingAccess: idToken.payload.hasBillingAccess === 'true',
                            accessExperimentalFeatures: idToken.payload.accessExperimentalFeatures === 'true'
                        };

                        this.setPreferredLanguage(idToken.payload.locale.slice(0,2));
                        this.token = session.getIdToken().getJwtToken();
                        this.tokenExpiration = session.getIdToken().getExpiration();

                        if (this.isLoggedIn) { // welcome message only when user has just logged in
                            if (this.profile.firstLoginDate)
                                toast.success("Hi " + this.profile.firstName + ", welcome back!");
                            else {
                                // TODO: if this is the first login, we could present a Welcome page!
                                toast.success("Hi " + this.profile.firstName + ", welcome to Provingly Apps!!");
                            }
                        }                        
                    } 
                    catch (e) {
                        console.log('User is not logged in');
                    }
                } 
                else {
                    console.log('User already logged in')
                }
            } 
            catch(err) {
                console.log("Error: ", err)
            }
        },
        async logout() {
            try {
                await Auth.signOut();
                this.reset();
                this.router.replace('/')

            } catch (error) {
                console.log('error signing out: ', error);
                throw Error(error)
            }
        },
        async signUp(
            signUpData
            ) {
                const url = import.meta.env.VITE_INVITE_URL;
                const inviter = this.profile.firstName + " " + this.profile.lastName;
                console.log(inviter);
                let signUpBody = {
                    email: signUpData.email.toLowerCase(), // we use lower case to make subsequent lookups more robust
                    given_name: signUpData.given_name,
                    family_name: signUpData.family_name,
                    singleParticipation: signUpData.singleParticipation || true,
                    hasBillingAccess: signUpData.hasBillingAccess || false,
                    isUserAdmin: signUpData.isUserAdmin || false,
                    levelAsTester: signUpData.levelAsTester || "beta",
                    locale: signUpData.locale || "en_US",
                    isActive: signUpData.isActive || true,
                    mainRole: signUpData.mainRole || "handler",
                    defaultOrganization: signUpData.defaultOrganization || this.profile.organization,
                    inviter: inviter
                };
                if (signUpData.expirationDate && signUpData.expirationDate !== '')
                    signUpBody['expirationDate'] = signUpData.expirationDate;
                if (signUpData.userId && signUpData.userId !== '')
                    signUpBody['userId'] = signUpData.userId;

                try {
                    await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': this.token
                        },
                        body: JSON.stringify(signUpBody)
                    })
                    console.log("User created")
                }
                catch (error) {
                    console.log('error signing up: ', error);
                    throw Error(error)
            }

        },
        reset() {
            this.token = null;
        },
        setUseDeveloperFeatures(value: boolean) {
            this.useDeveloperFeatures = value;
            console.log(this.useDeveloperFeatures)
        },
        setUseMockupFeatures(value: boolean) {
            this.useMockupFeatures = value;
        },
        setPreferredLanguage(value: string) {
            this.preferredLanguage = value;
            i18n.global.locale.value = value
        },
        roleGreaterOrEqual(leftRole: Role, rightRole: Role) {
          // Checks if leftRole >= rightRole
          return this.profile.mainRole.indexOf(leftRole) >= this.profile.mainRole.indexOf(rightRole);
        }
    }
})
