<script setup>
import { useUserStore } from '@/stores/auth'
import { useFileStore } from '@/stores/cases'; // Adjust the path to the location of your fileStore
import { onMounted, computed } from 'vue'
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header/Header.vue'
import Footer from './components/Footer.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const userStore = useUserStore()
const fileStore = useFileStore()

const hasFooter = computed(() => {
  return route.meta.hasFooter
})

onMounted(() => {
  // So the cases page doesnt break when refreshing page.
  fileStore.setCasesOpen(false)
})

</script>
<template>
  <div id="app">
    <div class="content">
      <Header v-if="userStore.isLoggedIn" />
      <router-view />
    </div>
    <Footer v-if="hasFooter" />
  </div>
</template>
<style scoped>
#app {
  min-height: 100vh;
  background-color: var(--background-lighter);
}
.content {
  min-height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  min-width: 90ch;
}

@media screen and (max-width: 1000px) {
  .content {
    min-height: calc(100vh - 150px);
  }
}
</style>
